import React from 'react';
import styled from 'styled-components';
import Layout from '../components/layout';
import SEO from '../components/seo';
import { headingMixin, paragraphMixin } from '../theme/mixins';

const PageWrapper = styled.div`
  margin-top: 200px;
  margin-bottom: 300px;
  text-align: center;
  ${paragraphMixin}
  h1 {
    ${headingMixin}
  }
`;

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <PageWrapper>
      <h1>404: Not Found</h1>
      <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
    </PageWrapper>
  </Layout>
);

export default NotFoundPage;
